import api from ".";

export const getAladinStats = () => {
  return api.get("https://api.heartlive.net/");
};

export const changeDP = (dp) => {
  return api.patch("https://api.heartlive.net/api/utils/games/aladin/change-dp", {
    dp,
  });
};
