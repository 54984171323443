import api from ".";

export const getWinRate = () => {
  return api.get("/greedy/get_win_rate");
};

export const updateWinRate = (a, b, c) => {
  return api.put("/greedy/update_win_rate", {
    a,
    b,
    c,
  });
};

export const getPlayers = (skip = 0, limit = 20, orders = []) => {
  return api.get("/greedy/players", {
    params: {
      skip,
      limit,
      orders: JSON.stringify(orders),
    },
  });
};

export const getHistory = () => {
  return api.get(
    `${process.env.REACT_APP_SOCKET_BASE_URL}/game-history/luck77`,
    {
      params: {
        uid: process.env.REACT_APP_SOCKET_JWT,
      },
    }
  );
};
