import React, { useEffect } from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { getStats } from "api/dashboard";
import { changeStats } from "store/slice/dashboard";
import { numberFormatter } from "utils/number";
import Settings from "components/Settings/Settings";
import GreedyEarningChart from "components/charts/GreedyEarningChart";
import TeenPattiEarningChart from "components/charts/TeenPattiEarningChart";
import Lucky77EarningChart from "components/charts/Lucky77EarningChart";
import HourlyChart from "components/charts/HourlyChart";
import EarningChart from "components/charts/EarningChart";

function Dashboard() {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.dashboard.stats);

  const loadStats = async () => {
    try {
      const { data } = await getStats();

      dispatch(changeStats(data));
    } catch (error) {
      NotificationManager.error(error?.response?.data?.error?.message || error.message);
    }
  };

  useEffect(() => {
    loadStats();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-single-02 text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Players</p>
                      <Card.Title as="h4">{numberFormatter(stats.totalPlayers)}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo me-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-controller-modern text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Games</p>
                      <Card.Title as="h4">{numberFormatter(stats.totalGames)}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo me-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        <Row>
          <Settings title="Teen Patti" gameName="teen-patti" configName="teenPatti" />
          <Settings title="Lucky 77" gameName="lucky-77" configName="lucky77" />
        </Row>

        <Row>
          <Col md={6}>
            <EarningChart name="TEEN_PATTI" />
          </Col>
          <Col md={6}>
            <EarningChart name="LUCKY_77" />
          </Col>
          <Col md={6}>
            <EarningChart name="FERRIS_WHEEL" />
          </Col>
          <Col md={6}>
            <EarningChart name="ROULETTE" />
          </Col>
          <Col sm={12}>
            <HourlyChart name="TEEN_PATTI" />
          </Col>
          <Col sm={12}>
            <HourlyChart name="LUCKY_77" />
          </Col>
          <Col sm={12}>
            <HourlyChart name="FERRIS_WHEEL" />
          </Col>
          <Col sm={12}>
            <HourlyChart name="ROULETTE" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
